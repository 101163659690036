import { useRef } from "react"
import { dehydrate, QueryClient } from "react-query"
import NextImg from "next/image"
import HTMLParser from "react-html-parser"
import { twMerge } from "tailwind-merge"
import {
  CityBoard,
  Footer,
  GuidesCarousel,
  HeadMeta,
  Header,
  PureAds,
  ContentTemplate,
  useSmartBanner,
  ButtonDecorated,
} from "components"
import { getHost, getArticleUrl, LocaleContext, DEFAULT_LOCALE } from "lib"
import {
  prefetchAuthors,
  prefetchCities,
  prefetchCommonTranslations,
  prefetchPageData,
  prefetchSmartBanner,
  useGetAuthors,
  useGetCities,
  useGetPageData,
  getI18nLocales,
  getSmartBanner,
} from "api"
import { SMART_BANNER_PATH_VALUES } from "lib/isr"
import type { LegacyRef } from "react"
import type { NextPageContext } from "next"
import type { Card } from "components"
import type { GetStaticProps, GetStaticPaths } from "next"
import type { SmartBannerPathValues } from "lib/isr"
import type { SmartBannerData } from "api"

import KingIcon from "public/images/icons/king.svg"
import CloudTop from "public/images/cloud.svg"
import CloudTopMobile from "public/images/cloud-mobile.svg"
import MainPng from "public/images/The-City.png"
import BananaPng from "public/images/Banana.png"

interface MainPageProps {
  url: string
  smartBannerData?: SmartBannerData
}

type RootParsedUrlQuery = {
  smartBanner: SmartBannerPathValues,
}

export const getStaticProps: GetStaticProps<{}, RootParsedUrlQuery> = async(ctx) => {
  const { locale, params } = ctx
  const queryClient = new QueryClient()

  await Promise.all([
    prefetchPageData(queryClient, "main", locale),
    prefetchCommonTranslations(queryClient, locale),
    prefetchCommonTranslations(queryClient, DEFAULT_LOCALE),
    prefetchCities(queryClient, locale),
    prefetchAuthors(queryClient, locale),
    prefetchSmartBanner(queryClient, locale),
  ])

  const smartBannerData = await getSmartBanner(locale)

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      url: getHost(locale),
      smartBannerData: params?.smartBanner === "smart-banner-disabled" ? null : smartBannerData,
    },
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  const paths: any[] = []
  const locales = await getI18nLocales()

  locales.forEach(({ code }) => {
    SMART_BANNER_PATH_VALUES.forEach(smartBanner => {
      paths.push({ params: { smartBanner }, locale: code })
    })
  })

  return {
    paths,
    fallback: true,
  }
}

export default function Main(props: MainPageProps) {
  const { url, smartBannerData} = props
  const { isLoading, data } = useGetPageData("main")
  const { data: authors = [] } = useGetAuthors()
  const { data: cities = [] } = useGetCities()
  const smartBanner = useSmartBanner(smartBannerData)
  const boardRef: LegacyRef<HTMLDivElement> = useRef(null)

  if (isLoading || !data) {
    return null
  }

  const cards = authors.reduce((result: Card[], author) => {
    const firstArticle = author.articles
      .slice()
      .sort(
        (a, b) => Date.parse(a.published_at) - Date.parse(b.published_at),
      )[0]
    if (firstArticle) {
      result.push({
        author: author,
        city: firstArticle.city,
        description: author.description,
        url: getArticleUrl(firstArticle.slug),
        className: "group-hover:bg-white",
      })
    }
    return result
  }, [])

  const onButtonClick = () => {
    boardRef?.current?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <LocaleContext.Provider value={data}>
      <HeadMeta title={data.locales["tab.title"]} meta={data.meta} url={url} />
      {smartBanner}
      <div className={twMerge(smartBanner && "mt-20 md:mt-[52px]")}>
        <Header theme="dark" variant="menu" />
      </div>
      <div className="mx-auto overflow-hidden md:max-w-5xl lg:max-w-screen-xl">
        <div className="px-4 pt-14 pb-10 text-center md:px-10 md:pt-20">
          <h1 className="font-serif text-5xl uppercase tracking-wider text-darkgold-300 md:text-7xl">
            {HTMLParser(data.locales["main.title"])}
          </h1>
          <p className="mx-auto mt-4 mb-6 max-w-4xl font-sans text-xl text-white md:text-4xl">
            {HTMLParser(data.locales["main.paragraph"])}
          </p>
          <ButtonDecorated theme="dark" onClick={onButtonClick}>
            {HTMLParser(data.locales["main.button"])}
          </ButtonDecorated>
        </div>

        <div className="relative w-full md:px-10 lg:px-25">
          <NextImg
            layout="responsive"
            objectFit="contain"
            src={MainPng.src}
            width={MainPng.width}
            height={MainPng.height}
            unoptimized
          />
          <div className="absolute top-5 left-3 right-0 mx-auto w-[44%] origin-center animate-fly sm:top-8 md:left-7 md:top-10 lg:top-20 lg:w-2/5">
            <NextImg
              src={BananaPng.src}
              width={BananaPng.width}
              height={BananaPng.height}
              unoptimized
            />
          </div>
        </div>

        <div
          ref={boardRef}
          className="px-4 pt-12 pb-16 md:px-10 md:py-14 lg:px-20 lg:pt-18"
        >
          <h3 className="pb-2 font-serif text-xl italic md:text-2xl md:leading-7 lg:pb-4 lg:text-3xl">
            {data.locales["city_board.title"]}
          </h3>
          <CityBoard cities={cities} />
        </div>
      </div>

      <ContentTemplate className={`relative mt-[50px] text-white lg:mt-20`}>
        <CloudTop className="hidden md:absolute md:top-[-55px] md:left-0 md:block md:h-14 lg:right-64 lg:-top-[81px] lg:h-[82px]" />
        <CloudTopMobile className="absolute top-[-49px] right-0 h-[51px] md:hidden" />
      </ContentTemplate>
      <PureAds />

      <div className="pb-20 pt-22 md:pt-36 md:pb-24">
        <div className="mx-4 mb-10 md:mx-10 md:mb-14 lg:text-center">
          <h3 className="relative mb-4 inline-block font-serif text-3xl uppercase md:text-6xl">
            <KingIcon className="mb-2 h-8 w-8 md:h-[52px] md:w-[52px] lg:absolute lg:-top-12 lg:-right-6" />
            {data.locales["carousel.title"]}
          </h3>
          <p className="text-xl md:text-4xl lg:mx-auto lg:max-w-[820px]">
            {data.locales["carousel.description"]}
          </p>
        </div>
        {cards.length && <GuidesCarousel cards={cards} />}
      </div>

      <Footer />
    </LocaleContext.Provider>
  )
}
